'use es6';

import { PLACEHOLDER_IMG_SELECTOR } from '../constants';
export function adjustPlaceholderImageSize(wrapperEl) {
  const imageEl = wrapperEl.querySelector(PLACEHOLDER_IMG_SELECTOR);
  if (!imageEl) {
    return;
  }
  if (wrapperEl.style.maxHeight) {
    imageEl.style.maxHeight = wrapperEl.style.maxHeight;
  }
}