'use es6';

import { debugLog } from '../utils/logging';
import { appendUtkToIframeUrl, fetchOembedUrl } from '../utils/oembed';

// for compatibility with Embed field markup and JS snippet
const containerSelector = '.oembed_container';
const iframeWrapperSelector = '.iframe_wrapper[data-source-type="media_bridge"]';
let containerEls;
let utk;
function adjustIframeSize(containerEl, data, iframeEl) {
  // keep in sync with Embed field JS snippet
  const iframeWrapper = containerEl.querySelector(iframeWrapperSelector);
  const maxHeight = iframeWrapper.dataset.maxHeight !== undefined && !iframeWrapper.dataset.maxHeight ? data.height : iframeWrapper.dataset.maxHeight;
  const maxWidth = iframeWrapper.dataset.maxWidth !== undefined && !iframeWrapper.dataset.maxWidth ? data.width : iframeWrapper.dataset.maxWidth;
  const height = iframeWrapper.dataset.height !== undefined && !iframeWrapper.dataset.height ? data.height : iframeWrapper.dataset.height;
  const width = iframeWrapper.dataset.width !== undefined && !iframeWrapper.dataset.width ? data.width : iframeWrapper.dataset.width;
  if (maxHeight) {
    const maxHeightStr = `${maxHeight.toString(10)}px`;
    containerEl.style.maxHeight = maxHeightStr;
    if (iframeEl) {
      iframeEl.style.maxHeight = maxHeightStr;
    }
  }
  if (maxWidth) {
    const maxWidthStr = `${maxWidth.toString(10)}px`;
    containerEl.style.maxWidth = maxWidthStr;
    if (iframeEl) {
      iframeEl.style.maxWidth = maxWidthStr;
    }
  }
  if (height) {
    const heightStr = `${height.toString(10)}px`;
    containerEl.style.height = heightStr;
    if (iframeEl) {
      iframeEl.style.height = heightStr;
    }
  }
  if (width) {
    const widthStr = `${width.toString(10)}px`;
    containerEl.style.width = widthStr;
    if (iframeEl) {
      iframeEl.style.width = widthStr;
    }
  }
}
function renderPlayer(containerEl, iframeWrapper, oembedResp) {
  if (containerEl.querySelector('iframe') && utk) {
    const iframeEl = containerEl.querySelector('iframe');
    debugLog('Found existing iframe, updating src to', containerEl, oembedResp, iframeEl.src);
    iframeEl.src = appendUtkToIframeUrl(iframeEl.src, utk);
    return;
  }
  const tempWrapperEl = document.createElement('div');
  tempWrapperEl.innerHTML = oembedResp.html;
  const iframeEl = tempWrapperEl.querySelector('iframe');
  if (iframeEl) {
    iframeEl.src = appendUtkToIframeUrl(iframeEl.src, utk);
    debugLog('Created iframe for legacy embed', containerEl, oembedResp, iframeEl.src);
  } else {
    debugLog('Injecting oembed html without iframe', containerEl, oembedResp);
  }
  iframeWrapper.dataset.mbStatus = 'loaded';
  tempWrapperEl.childNodes.forEach(node => iframeWrapper.appendChild(node));
  adjustIframeSize(containerEl, oembedResp, iframeEl);
}
function fetchAndRender(containerEl) {
  const iframeWrapper = containerEl.querySelector(iframeWrapperSelector);
  if (!iframeWrapper) {
    return;
  }
  const oembedUrl = iframeWrapper.dataset.embedUrl;
  if (!oembedUrl || iframeWrapper.dataset.mbHelperIgnore) {
    return;
  }
  fetchOembedUrl(oembedUrl).then(resp => resp.json()).then(data => renderPlayer(containerEl, iframeWrapper, data)).catch(err => {
    console.warn(err);
    containerEl.dataset.mbStatus = 'fetchFailed';
  });
}
export function renderLegacyPlayers(_utk) {
  utk = _utk;
  containerEls = document.querySelectorAll(containerSelector);
  containerEls.forEach(fetchAndRender);
}