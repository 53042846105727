'use es6';

export function isDebug() {
  if (window._hsMbDebug) {
    return true;
  }
  try {
    return Boolean(localStorage && localStorage.getItem('mb_embed_js_debug'));
  } catch (e) {
    return false;
  }
}
export function debugLog(...args) {
  if (isDebug()) {
    console.log.apply(console, ['[media-bridge-embed]', ...args]);
  }
}