/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import { IFRAME_WRAPPER_SELECTOR, PLACEHOLDER_SELECTOR, UTK_LOAD_TIMEOUT, WRAPPER_SELECTOR } from './constants';
import { debugLog } from './utils/logging';
import { appendQueryParam, appendUtkToIframeUrl, fetchOembedUrl } from './utils/oembed';
import { renderLegacyPlayers } from './compat/embedFieldSnippet';
import { adjustPlaceholderImageSize } from './utils/dimensions';
export default function ({
  testingMode = false
} = {}) {
  window._hsq = window._hsq || [];
  window._hsp = window._hsp || [];
  const mediaBridge = window._hsMediaBridge = window._hsMediaBridge || {
    embedsByUrl: {}
  };
  const deferredPlayers = {};
  let utkUnavailable = false;
  let privacyConsent;
  let utk;
  function injectIframe(wrapperEl, iframeEl, embedHtmlNodes, iframeSrc) {
    iframeEl.src = iframeSrc;
    const contentWrapperEl = wrapperEl.querySelector(IFRAME_WRAPPER_SELECTOR) || wrapperEl;
    embedHtmlNodes.forEach(node => contentWrapperEl.appendChild(node));
  }
  function executeScripts(el) {
    Array.from(el.querySelectorAll('script')).forEach(oldScript => {
      const newScript = document.createElement('script');
      Array.from(oldScript.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
      newScript.appendChild(document.createTextNode(oldScript.innerHTML));
      oldScript.parentNode.replaceChild(newScript, oldScript);
    });
  }
  function revealPlayer(wrapperEl, html) {
    debugLog('revealing player', wrapperEl);
    wrapperEl.querySelectorAll(PLACEHOLDER_SELECTOR).forEach(el => el.style.display = 'none');
    const iframeEl = wrapperEl.querySelector('iframe');
    iframeEl.style.display = 'block';
    executeScripts(wrapperEl, html);
    wrapperEl.dataset.mbStatus = utk ? 'revealed' : 'revealedWithoutUtk';
  }
  function shouldRender(wrapperEl, mbStatus) {
    if (mbStatus === 'loaded' || mbStatus === 'revealed') {
      return false;
    }
    if (!utk && (mbStatus === 'loadedWithoutUtk' || mbStatus === 'revealedWithoutUtk')) {
      return false;
    }
    return true;
  }
  function renderPlayer(wrapperEl) {
    const oembedUrl = wrapperEl.dataset.oembedUrl;
    if (!oembedUrl) {
      debugLog(`All ${WRAPPER_SELECTOR} elements must declare data-oembed-url`);
      return;
    }
    // we rely on each embed field to place its resp as JSON keyed off the oembed url
    const oembedResp = mediaBridge.embedsByUrl[oembedUrl];
    const mbStatus = wrapperEl.dataset.mbStatus;
    if (!shouldRender(wrapperEl, mbStatus)) {
      debugLog(`Not touching wrapper for url ${oembedUrl} due to status ${mbStatus}`);
      return;
    }
    let iframeEl = wrapperEl.querySelector('iframe');
    let iframeSrc;
    let embedHtmlNodes;
    if (iframeEl) {
      debugLog('Found existing iframe in wrapperEl', wrapperEl, iframeEl.src);
      if (utk) {
        iframeSrc = appendUtkToIframeUrl(iframeEl.src, utk);
        if (iframeEl.src !== iframeSrc) {
          // todo - figure out hard refresh concerns updating iframe url, maybe use postMessage
          debugLog('Updating existing player iframe url to', oembedUrl, iframeSrc);
          iframeEl.src = iframeSrc;
        }
      }
      wrapperEl.dataset.mbStatus = utk ? 'loaded' : 'loadedWithoutUtk';
      return;
    } else if (oembedResp && oembedResp.html) {
      // create invisible iframe in wrapper.
      const iframeWrapperEl = document.createElement('div');
      iframeWrapperEl.innerHTML = oembedResp.html;
      iframeEl = iframeWrapperEl.querySelector('iframe');
      if (!iframeEl) {
        debugLog('Did not find an iframe in oembed html, injecting anyway', oembedResp, oembedUrl);
        const contentWrapperEl = wrapperEl.querySelector(IFRAME_WRAPPER_SELECTOR) || wrapperEl;
        contentWrapperEl.innerHTML = oembedResp.html;
        executeScripts(iframeWrapperEl);
        // we won't try to pass a utk obtained later, so set `loaded` instead of `loadedWithoutUtk`
        wrapperEl.dataset.mbStatus = 'loaded';
        return;
      } else {
        iframeSrc = appendUtkToIframeUrl(iframeEl.src, utk);
        debugLog(`Creating player iframe for oembed url ${oembedUrl} with src ${iframeSrc}`);
        iframeEl.style.display = 'none';
        embedHtmlNodes = iframeWrapperEl.childNodes;
      }
    } else {
      debugLog(`Could not find oembed url ${oembedUrl} in \`window._hsMediaBridge.embedsByUrl\`, use the render_oembed_html macro to set it`);
      return;
    }
    if (mbStatus === 'revealedWithoutUtk') {
      wrapperEl.dataset.mbStatus = 'revealed';
      return;
    }
    wrapperEl.dataset.mbStatus = utk ? 'loaded' : 'loadedWithoutUtk';
    const revealOn = wrapperEl.dataset.revealOn;
    if (revealOn === 'click') {
      if (!wrapperEl.revealListenerAttached) {
        wrapperEl.addEventListener('click', () => {
          iframeSrc = appendUtkToIframeUrl(iframeEl.src, utk);
          iframeSrc = appendQueryParam(iframeSrc, 'autoplay', 1);
          injectIframe(wrapperEl, iframeEl, embedHtmlNodes, iframeSrc);
          revealPlayer(wrapperEl, oembedResp.html);
        }, {
          once: true
        });
      }
      wrapperEl.revealListenerAttached = true;
    } else if (revealOn === 'defer') {
      deferredPlayers[oembedUrl] = () => {
        injectIframe(wrapperEl, iframeEl, embedHtmlNodes, iframeSrc);
        revealPlayer(wrapperEl, oembedResp.html);
      };
    } else {
      injectIframe(wrapperEl, iframeEl, embedHtmlNodes, iframeSrc);
      revealPlayer(wrapperEl, oembedResp.html);
    }
  }
  function handleFetchSuccess(wrapperEl, oembedUrl, resp) {
    resp.json().then(data => {
      mediaBridge.embedsByUrl[oembedUrl] = data;
      if (utk || utkUnavailable) {
        renderPlayer(wrapperEl);
      }
    });
  }
  function renderPlayers() {
    const playerEls = document.querySelectorAll(WRAPPER_SELECTOR);
    debugLog('Found MB players', playerEls);
    playerEls.forEach(renderPlayer);
    renderLegacyPlayers(utk);
  }
  function codeNotLoadEventHandler(e) {
    debugLog('tracking js not loaded or utk not found after timeout', e);
    utkUnavailable = true;
    window.removeEventListener('content-analytics-code-not-loaded', codeNotLoadEventHandler);
    renderPlayers();
  }
  function setUtk(_utk) {
    utk = _utk;
  }
  function onLoad() {
    const playerEls = document.querySelectorAll(WRAPPER_SELECTOR);
    const legacyPlayerEls = document.querySelectorAll(`.oembed_container`);
    if (!playerEls.length && !legacyPlayerEls.length) {
      return;
    }
    playerEls.forEach(wrapperEl => {
      const oembedUrl = wrapperEl.dataset.oembedUrl;
      if (!oembedUrl) {
        return;
      }
      const hasIframe = wrapperEl.querySelector('iframe');
      if (!mediaBridge.embedsByUrl[oembedUrl] && !hasIframe) {
        fetchOembedUrl(oembedUrl).then(resp => {
          if (resp.ok) {
            handleFetchSuccess(wrapperEl, oembedUrl, resp);
          } else {
            console.warn(`failed to fetch oembed url ${oembedUrl}`, resp);
            wrapperEl.dataset.mbStatus = 'fetchFailed';
          }
        });
      }
      adjustPlaceholderImageSize(wrapperEl);
    });
    if (!testingMode && !mediaBridge.skipTimeout) {
      setTimeout(() => {
        const timeoutEvent = document.createEvent('CustomEvent');
        timeoutEvent.initCustomEvent('content-analytics-code-not-loaded');
        window.dispatchEvent(timeoutEvent);
      }, UTK_LOAD_TIMEOUT);
    }
    mediaBridge.initialized = true;
  }
  function listenForPrivacyConsent() {
    window._hsp.push(['addPrivacyConsentListener', _privacyConsent => {
      privacyConsent = _privacyConsent;
      window._hsq.push(['addUserTokenListener', onTrackingCodeLoaded]);
    }]);
  }
  function onTrackingCodeLoaded(_utk) {
    if (!privacyConsent) {
      debugLog('Could not obtain utk cookie and privacyConsent', _utk, privacyConsent);
    } else if (privacyConsent.allowed && _utk && !utk) {
      setUtk(_utk);
      debugLog('Received utk cookie with privacyConsent', _utk, privacyConsent);
    }
    renderPlayers();
    window.removeEventListener('content-analytics-code-not-loaded', codeNotLoadEventHandler);
  }
  if (mediaBridge.initialized) {
    debugLog('already initialized, not attaching listeners');
  } else {
    window.addEventListener('content-analytics-code-not-loaded', codeNotLoadEventHandler);
    listenForPrivacyConsent();
    if (document.readyState === 'interactive' || document.readyState === 'complete') {
      onLoad();
    } else {
      document.addEventListener('DOMContentLoaded', onLoad);
    }
  }
  if (testingMode) {
    return {
      renderPlayers,
      setUtk,
      onLoad
    };
  }
  return {
    fetchOembedUrl,
    revealDeferredPlayer: url => {
      const player = deferredPlayers[url];
      if (player) {
        player();
        delete deferredPlayers[url];
      } else {
        console.warn(`Deferred player for oembed url ${url} not found`, url);
      }
    }
  };
}