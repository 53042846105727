'use es6';

// query param passed to embed iframes when we have the hubspotutk cookie
export const UTK_COOKIE_QUERY_PARAM = 'hs_utk';
export const PORTAL_ID_QUERY_PARAM = 'hs_portal_id';
// players will be rendered without a utk after this timeout past DOMContentLoaded
export const UTK_LOAD_TIMEOUT = 3000;
export const WRAPPER_CLASSNAME = 'hs-mb-embed-wrapper';
export const WRAPPER_SELECTOR = `.${WRAPPER_CLASSNAME}`;
export const IFRAME_WRAPPER_SELECTOR = '.hs-mb-iframe-wrapper';
export const PLACEHOLDER_CLASSNAME = 'hs-mb-embed-placeholder';
export const PLACEHOLDER_SELECTOR = `.${PLACEHOLDER_CLASSNAME}`;
export const PLACEHOLDER_IMG_SELECTOR = `img.${PLACEHOLDER_CLASSNAME}`;