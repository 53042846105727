/* hs-eslint ignored failing-rules */
/* eslint-disable prefer-promise-reject-errors */

'use es6';

import { PORTAL_ID_QUERY_PARAM, UTK_COOKIE_QUERY_PARAM } from '../constants';
const HS_STATIC_APP_DOMAINS = ['.hubspotqa.com', '.hubspot.com', '.hubteamqa.com', '.hubteam.com'];
function isHsCmsPage() {
  return Boolean(window.hsVars);
}
function getPortalId() {
  return typeof window.hsVars === 'object' ? window.hsVars.portal_id : null;
}
function isStaticApp() {
  return HS_STATIC_APP_DOMAINS.some(domain => window.location.hostname.endsWith(domain));
}
export function fetchOembedUrl(url) {
  const query = new window.URLSearchParams({
    url,
    source: 'mb-embed-js'
  });
  if (isHsCmsPage()) {
    return fetch(`/_hcms/oembed?${query}`);
  } else if (isStaticApp()) {
    return fetch(`//api.hubapi.com/oembedproxy/v1/oembed?${query}`);
  }
  return Promise.reject('Cannot fetch oembed url, must be on a CMS page or static app domain.');
}
export function appendUtkToIframeUrl(iframeSrc, utk) {
  iframeSrc = String(iframeSrc);
  if (utk) {
    if (iframeSrc.indexOf(UTK_COOKIE_QUERY_PARAM) >= 0) {
      return iframeSrc;
    }
    const anchor = document.createElement('a');
    anchor.href = iframeSrc;
    // only touch absolute urls (http://, https://, about:)
    if (anchor.protocol && !iframeSrc.startsWith(anchor.protocol)) {
      return iframeSrc;
    }
    if (anchor.hash) {
      iframeSrc = iframeSrc.replace(anchor.hash, '');
    }
    if (iframeSrc.indexOf('?') >= 0) {
      iframeSrc += `&${UTK_COOKIE_QUERY_PARAM}=${utk}`;
    } else {
      iframeSrc += `?${UTK_COOKIE_QUERY_PARAM}=${utk}`;
    }
    if (getPortalId()) {
      iframeSrc = appendQueryParam(iframeSrc, PORTAL_ID_QUERY_PARAM, getPortalId());
    }
    if (anchor.hash) {
      iframeSrc += anchor.hash;
    }
  }
  return iframeSrc;
}
export function appendQueryParam(url, name, value) {
  if (url.indexOf(name) >= 0) {
    return url;
  }
  if (url.indexOf('?') >= 0) {
    url += `&${name}=${value}`;
  } else {
    url += `?${name}=${value}`;
  }
  return url;
}